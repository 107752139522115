import { api } from "service";
import { useState } from "react";
import { useQuery } from "react-query";
import { useLanguage, useModals } from "hooks";

export default function useBoard({ board, group, detail }) {
  const modalOption = useModals();
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  const getBoard = useQuery(
    ["boardList", board],
    () => {
      return api.board.getBoard(board);
    },
    {
      refetchOnWindowFocus: true,
      enabled: !!board,
    }
  );

  const getGroup = useQuery(
    ["boardGroupList", group],
    () => {
      return api.board.getGroup(group);
    },
    {
      refetchOnWindowFocus: true,
      enabled: !!group,
    }
  );

  const getBoardDetail = useQuery(
    ["boardDetail", detail],
    () => {
      return api.board.getBoardDetail(detail);
    },
    {
      refetchOnWindowFocus: true,
      enabled: !!detail,
    }
  );

  const deleteBoard = async (id) => {
    const result = await api.board.deleteBoard(JSON.stringify(id));
    if (result.success) {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = languageQuarter
          ? "삭제가 완료되었습니다."
          : "Deletion has been completed."
        return { ...e };
      });
    }
  };

  return {
    getGroup,
    getBoard,
    getBoardDetail,
    deleteBoard,
  };
}
