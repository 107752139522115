import Styles from "./styles";
import { common } from "components";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth, useLanguage, useModals } from "hooks";

// img
import IconArrowRight from "resources/image/icon/icon_arrow_right.svg";
import utils from "utils";

function MyPage() {
  const navigate = useNavigate();
  const modalOption = useModals();

  const authInfo = useAuth();
  const userDetail = authInfo?.getUserInfo?.data;

  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  const [push, setPush] = useState(false);

  useEffect(() => {
    setPush(userDetail?.push_yn == "Y" ? true : false);
  }, [userDetail]);

  const updatePush = () => {
    authInfo.updateUserInfo({
      push_yn: push == true ? "N" : "Y",
    });
  };

  const menuList = [
    {
      text: languageQuarter ? "푸시알림 설정" : "Push notification Setting",
    },
    {
      text: languageQuarter ? "앱 후기 남기기" : "Leave app reviews",
      onclick: () => utils.etc.callNative("APPREVIEW"),
    },
    {
      text: languageQuarter ? "문의하기" : "Contact Us",
      onclick: () => navigate("/ask"),
    },
    {
      text: languageQuarter ? "구독하기" : "Subscribe",
      onclick: () => {
        modalOption.setSubscriptionModal((e) => {
          e.show = true;

          return { ...e };
        });
      },
    },
    {
      text: languageQuarter ? "결제내역 확인하기" : "Check payment details",
      onclick: () => navigate("/mypage/payment/detail"),
    },
    {
      text: languageQuarter ? "개인정보처리방침" : "Privacy Policy",
      onclick: () => navigate("/terms/privacy"),
    },
    {
      text: languageQuarter ? "이용약관" : "Terms and Conditions",
      onclick: () => navigate("/terms/use"),
    },
    {
      text: languageQuarter ? "계정 설정" : "Account Settings",
      onclick: () => navigate("/mypage/account-setting"),
    },
    {
      text: languageQuarter ? "앱 업데이트" : "App Update",
    },
  ];

  const monthName = (month) => {
    if (month == 1) return "January";
    else if (month == 2) return "February";
    else if (month == 3) return "March";
    else if (month == 4) return "April";
    else if (month == 5) return "May";
    else if (month == 6) return "June";
    else if (month == 7) return "July";
    else if (month == 8) return "August";
    else if (month == 9) return "September";
    else if (month == 10) return "October";
    else if (month == 11) return "November";
    else if (month == 12) return "December";
  };

  const dateTypeChange = (date = "") => {
    return languageQuarter
      ? `${date.split("-")[0]}년 ${parseInt(date?.split("-")[1])}월 ${
          parseInt(date.split("-")[2]) + "일"
        }`
      : `${monthName(parseInt(date?.split("-")[1]))} ${date.split("-")[2]}, ${
          date.split("-")[0]
        }`;
  };

  return (
    <Styles.Container>
      <header>
        <div className="inner">
          <p className="nowPage">
            {languageQuarter ? "마이페이지" : "My Page"}
          </p>
        </div>
      </header>

      <main>
        <div className="subscription">
          <div className="inner">
            <div className="textWrap">
              <p className="premium roboto">PREMIUM</p>

              <p className="text">
                {userDetail?.pay_yn == "Y" ? (
                  <>
                    {languageQuarter
                      ? `${dateTypeChange(userDetail?.pay_expired_at.split(" ")[0])} 갱신 예정`
                      : `Scheduled for renewal on ${dateTypeChange(userDetail?.pay_expired_at.split(" ")[0])}`}
                  </>
                ) : languageQuarter ? (
                  <>
                    STAMFIT의 모든 것을 <span>끊김없이</span> 즐기세요{" "}
                  </>
                ) : (
                  <>Enjoy STAMFIT without interruption </>
                )}
              </p>
            </div>

            <button
              type="button"
              className="paymentButton"
              onClick={() => {
                if (authInfo.getUserInfo.data?.pay_yn == "Y") {
                  navigate("/mypage/payment/detail");
                  return;
                }

                modalOption.setSubscriptionModal((e) => {
                  e.show = true;
                  return { ...e };
                });
              }}
            >
              <img src={IconArrowRight} alt="IconArrowRight" />
            </button>
          </div>
        </div>

        <div className="inner">
          <ul>
            {menuList.map((item, index) => {
              return (
                <li
                  className="menuList"
                  key={"menuList" + index}
                  onClick={item.onclick}
                >
                  {item.text}

                  {item.text ===
                    (languageQuarter
                      ? "푸시알림 설정"
                      : "Push notification Setting") && (
                    <button
                      type="button"
                      className={"pushButton" + (push ? " on" : "")}
                      onClick={updatePush}
                    ></button>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </main>

      <div className="navigation">
        <common.NavigationBar />
      </div>
    </Styles.Container>
  );
}

export default MyPage;
