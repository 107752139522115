import Styles from "./styles";
import { IMAGEPATH } from "service/connector";
import { common, ui } from "components";
import { FeedDetailList } from "./components";
import { useBoard, useLanguage } from "hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

function FeedDetailPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const scrollDemoRef = useRef(null);
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";
  
  const [headerImage, setHeaderImage] = useState("");
  const [tabIndex, setTabIndex] = useState(location.state?.category);
  
  const boardInfo = useBoard({
    board: {
      category_info_id: tabIndex || "",
      page: 1,
      recordSize: 30,
    },
  });

  const data = boardInfo.getBoard?.data?.list || [];
  // boardInfo.getBoard.refetch();

  const tabList = languageQuarter
    ? [
      { text: "전체" },
      { text: "눈바디" },
      { text: "식사" },
      { text: "운동" },
      { text: "기타" },
    ] : [
      { text: "All" },
      { text: "Body" },
      { text: "Meal" },
      { text: "Workout" },
      { text: "Etc" },
    ];

  const handleScroll = (e) => {
    if (scrollDemoRef.current) {
      const { scrollTop, scrollLeft } = scrollDemoRef.current;
      let height = 0;
      let childList = e.target.getElementsByClassName("feedDetailList");
      for (let i = 0; i < childList.length; i++) {
        height += e.target.getElementsByClassName("feedDetailList")[i].offsetHeight;
        if (height - 1 >= scrollTop) {
          setHeaderImage(data[i].thumbnail);
          break;
        }
      }
    }
  };
  
  // 피드 디테일
  useEffect(() => {
    document.getElementById(location.state?.id)?.scrollIntoView({
      block: "start", location: "false",
    });
  }, [tabIndex]);

  // 첫 로드 흰색 배경 제거
  useEffect(() => {
    setHeaderImage(data[0]?.thumbnail);
  }, [data[0]?.thumbnail]);

  return (
    <Styles.Container>
      <header>
        <ui.button.PrevButton prevEvent={() => navigate("/")} />

        <ul className="tab inner">
          {tabList.map((item, index) => {
            return (
              <li
                className={"tabList" + (tabIndex === index ? " on" : "")}
                key={"tabList" + index}
                onClick={() => {
                  // MoveToTop()
                  navigate("/feed?disableScrollTop=true")
                  setTabIndex(index);
                }}
              >
                <span>{item.text}</span>
              </li>
            );
          })}
        </ul>

        <div className="bg">
          <img src={IMAGEPATH + headerImage} alt="" />
        </div>
      </header>

      <main onScroll={handleScroll} ref={scrollDemoRef}>
        {data.map((item, index) => {
          return (
            <div
              className="feedDetailList"
              key={"feedDetailList" + index}
              id={item.id}
            >
              <FeedDetailList data={item} />
            </div>
          );
        })}
      </main>

      <common.NavigationBar />
    </Styles.Container>
  );
}

export default FeedDetailPage;
