import Styles from "./styles";
import { useLanguage } from "hooks";
import { useEffect, useState } from "react";

// highchart
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

function PieChart({ data }) {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  const { carbohydrate, protein, fat, total } = data;

  const subtitleText = `<span>${
    total == 0 ? "--" : total
  }</span> <br/> total kcal`;
  const [options, setOptions] = useState({
    chart: {
      type: "pie",
      height: 313,
      reflow: true,
      backgroundColor: "#1D1D1D",
      plotBackgroundColor: null,
      plotShadow: false,
      marginTop: 0,
    },
    title: {
      text: languageQuarter
        ? "총 섭취 칼로리 및 영양성분 비율"
        : "Total calories intake and nutrient ratio",
      style: {
        fontFamily: "Pretendard Variable",
        fontSize: "14px",
        fontWeight: "600",
        color: "#fff",
      },
      align: "left",
    },
    plotOptions: {
      pie: {
        startAngle: -70,
        dataLabels: {
          enabled: true,
          // format: "{point.y}%",
          distance: -25,
          style: {
            fontFamily: "Roboto",
            fontSize: "16px",
            fontWeight: "600",
            color: "#090909",
            textOutline: "none",
          },
          formatter: function () {
            if (this.y == 0 || !this.y) return "--";
            return this.point.name;
          },
        },
        center: ["50%", "50%"],
        allowPointSelect: false,
        showInLegend: true,
      },
    },
    series: [
      {
        name: languageQuarter ? "비율" : "Ratio",
        data: [],
        size: "200px",
        innerSize: "50%",
        borderRadius: 0,
        borderWidth: 0,
        dataLabels: {
          formatter: function () {
            if (total == 0) return "--%";
            if (this.point.y === 0) {
              return "--"; // 값이 없을 때 표시할 텍스트
            }
            return this.point.y + "%"; // 값을 퍼센트로 표시
          },
        },
      },
    ],
    subtitle: {
      text: "",
      useHTML: true,
      align: "center",
      verticalAlign: "middle",
      y: 8,
      style: {
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: "300",
        color: "#fff",
        textAlign: "center",
      },
    },
    legend: {
      layout: "vertical",
      align: "left",
      verticalAlign: "bottom",
      x: -17,
      y: 20,
      itemMarginBottom: 0,
      floating: true,
      symbolRadius: 0,
      symbolWidth: 30,
      symbolHeight: 5,
      symbolPadding: 11,
      symbol: "rectangle",
      squareSymbol: false,
      useHTML: true,
      itemStyle: {
        fontFamily: "Pretendard Variable",
        fontSize: "12px",
        fontWeight: "300",
        color: "#fff",
        lineHeight: "20px",
      },
    },
    credits: {
      enabled: false,
    },
  });

  useEffect(() => {
    let dataList = [];
    dataList.push({
      name: languageQuarter ? "탄수화물" : "Carbohydrates",
      color: "#fff",
    });
    dataList.push({
      name: languageQuarter ? "단백질" : "Protein",
      color: "#A9A9A9",
    });
    dataList.push({
      name: languageQuarter ? "지방" : "Fat",
      color: "#595959",
    });

    let total = (carbohydrate || 0) + (protein || 0) + (fat || 0);
    let totalPer = 0;
    let per = 0;
    if (carbohydrate) {
      per = Math.round(((carbohydrate || 0) / total) * 100);
      totalPer += per;
      dataList[0].y = per;
    } else {
      dataList[0].y = 33.3;
    }
    if (protein) {
      per = Math.round(((protein || 0) / total) * 100);
      totalPer += per;
      dataList[1].y = per;
    } else {
      dataList[1].y = 33.3;
    }
    if (fat) {
      per = Math.round(((fat || 0) / total) * 100);
      totalPer += per;
      dataList[2].y = totalPer > 100 ? per - (totalPer - 100) : per;
    } else {
      dataList[2].y = 33.3;
    }

    setOptions((prevOptions) => ({
      ...prevOptions,
      series: [
        {
          ...prevOptions.series[0],
          ...{
            name: languageQuarter ? "비율" : "Ratio",
            data: [],
            size: "200px",
            innerSize: "50%",
            borderRadius: 0,
            borderWidth: 0,
            dataLabels: {
              formatter: function () {
                if (total == 0) return "--%";
                if (this.point.y === 0) {
                  return "--"; // 값이 없을 때 표시할 텍스트
                }
                return this.point.y + "%"; // 값을 퍼센트로 표시
              },
            },
          },
          data: dataList,
        },
      ],
      subtitle: {
        ...prevOptions.subtitle,
        text: subtitleText,
      },
    }));
  }, [data]);

  return (
    <Styles.Container>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Styles.Container>
  );
}

export default PieChart;
