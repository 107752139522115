import Styles from "./styles";
import ui from "components/ui";
import { useAuth, useLanguage } from "hooks";

// img
import IconDelete from "resources/image/icon/icon_delete.svg";
import utils from "utils";

function PushModal({ modalClose }) {
  const authInfo = useAuth();
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  const updatePush = (type) => {
    authInfo.updateUserInfo({
      push_yn: type,
    });
    modalClose();
    utils.etc.callNative("moveToMainToPage", "Stamp", "눈바디");
  };

  return (
    <Styles.Container>
      <div className="modalInner">
        <button type="button" className="closeButton" onClick={modalClose}>
          <img src={IconDelete} alt="IconDelete" />
        </button>

        <div className="top">
          <p className="title">{languageQuarter ? "푸시알림 동의" : "notification consent"}</p>
          {languageQuarter
            ? <p className="text">
              꾸준한 습관 형성을 위해 <br />
              동기부여 알림을 보내드려요
            </p>
            : <p className="text">
              We send you motivational <br />
              notifications to build habits!
            </p>
          }
        </div>

        <div className="bottom">
          <div className="buttonWrap">
            <ui.button.BasicButton
              buttonText={languageQuarter ? "허용안함" : "Don't Allow"}
              colorType={"black100"}
              sizeType={"height50"}
              onClick={() => {
                updatePush("N");
              }}
            />
            <ui.button.BasicButton
              buttonText={languageQuarter ? "허용하기" : "Allow"}
              colorType={"white"}
              sizeType={"height50"}
              onClick={() => {
                updatePush("Y");
              }}
            />
          </div>
          <p className="guideText">{
            languageQuarter
              ? "푸시알림은 마이페이지에서 변경 가능합니다"
              : "Notifications can be changed in My Page."
          }</p>
        </div>
      </div>

      <div className="overlay"></div>
    </Styles.Container>
  );
}

export { PushModal };

export default PushModal;
