import Styles from "./styles";
import { useLanguage } from "hooks";
import { useEffect, useState } from "react";

// highchart
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

Highcharts.setOptions({
  lang: {thousandsSep: ''}
});

function BarChart({ data, summaryData, type }) {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";
  const { eat, consume } = data;
  const [selectIndex, setSelectIndex] = useState(0);

  const weekOrdinal = ["1st", "2nd", "3rd", "4th", "5th", "6th"]
  const weekName = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

  let week = summaryData.food?.list[selectIndex]?.week_label?.split(" ")[1];
  let date = summaryData.food?.list[selectIndex]?.date
    ?.split(" ")[0]
    ?.split("-");
  let day = languageQuarter ? ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"] : weekName;

  const monthName = (month) => {
    if(month == 1)  return "January"
    else if(month == 2) return "February" 
    else if(month == 3) return "March"
    else if(month == 4) return "April"
    else if(month == 5) return "May"
    else if(month == 6) return "June"
    else if(month == 7) return "July"
    else if(month == 8) return "August"
    else if(month == 9) return "September"
    else if(month == 10) return "October"
    else if(month == 11) return "November"
    else if(month == 12) return "December"
  }

  const subtitleText =
    (type == "monthly"
      ? languageQuarter
        ? `<span>${date && date[1]}월 ${week}주차 평균섭취 & 소모 칼로리`
        : `<span>the ${weekOrdinal[week-1]} week of ${monthName(parseInt(date && date[1]))}`
      : languageQuarter
        ? `<span>${date && date[1]}월 ${date && date[2]}일
          ${day[new Date(summaryData.food?.list[selectIndex]?.date?.split(" ")[0]).getDay()]} 섭취 & 소모 칼로리`
        : `<span>${day[new Date(summaryData.weight?.list[selectIndex]?.date?.split(" ")[0]).getDay()]},
          ${monthName(parseInt(date && date[1]))} ${parseInt(date && date[2])}`
    ) + `</span> <br/> <b>${eat[selectIndex]}kcal / ${consume[selectIndex]}kcal</b>`;

  const [options, setOptions] = useState({
    chart: {
      type: "column",
      backgroundColor: "#FFFFFF",
      style: {
        color: "#1d1d1d",
      },
      height: 247,
      reflow: true,
      spacing: [4, 0, 8, 0],
    },
    title: {
      text: languageQuarter
        ? "섭취 & 소모 칼로리"
        : "Intake and Burned calories",
      align: "left",
      style: {
        fontFamily: "Pretendard Variable",
        fontSize: "14px",
        fontWeight: "600",
        color: "#1d1d1d",
      },
    },
    subtitle: {
      text: "",
      align: "left",
      useHTML: true,
      y: 40,
      style: {
        fontFamily: "Pretendard Variable",
        fontSize: "12px",
        fontWeight: "300",
        color: "#1d1d1d",
      },
    },
    xAxis: {
      categories: [],
      crosshair: true,
      labels: {
        y: 23,
        style: {
          fontFamily: "Roboto",
          fontSize: "14px",
          fontWeight: "300",
          color: "#090909",
        },
      },
      lineWidth: 0,
    },
    yAxis: {
      min: 0,
      title: {
        text: null,
      },
      labels: {
        enabled: false,
      },
      gridLineWidth: 0,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        pointWidth: 13,
        groupPadding: 0.15,
      },
      series: {
        cursor: "pointer",
        point: {
          events: {
            click: function (event) {
              setSelectIndex(this.index);
            },
          },
        },
      },
    },
    series: [],
    legend: {
      floating: true,
      align: "right",
      verticalAlign: "top",
      layout: "vertical",
      x: 4,
      y: -16,
      symbolRadius: 0,
      symbolWidth: 20,
      symbolHeight: 5,
      symbolPadding: 11,
      symbol: "rectangle",
      squareSymbol: false,
      useHTML: true,
      itemStyle: {
        fontFamily: "Pretendard Variable",
        fontSize: "12px",
        fontWeight: "300",
        color: "#1d1d1d",
        lineHeight: "20px",
      },
    },
    credits: {
      enabled: false,
    },
  });

  const refresh = () => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      series: [
        {
          name: languageQuarter ? "섭취" : "Intake",
          data: eat || [],
          color: "#090909",
        },
        {
          name: languageQuarter ? "소모" : "Burned",
          data: consume || [],
          color: "#6D6D6D",
        },
      ],
      subtitle: {
        ...prevOptions.subtitle,
        text: subtitleText,
      },
      xAxis: {
        ...prevOptions.xAxis,
        categories:
          type === "weekly"
            ? weekName.map(i => i.slice(0, 2))
            : weekOrdinal,
        labels: {
          enabled: true,
          events: {
            click: function (event) {
              const chart = event.target;
              const data =
                type === "weekly"
                  ? weekName.map(i => i.slice(0, 2))
                  : weekOrdinal;
              data.map((value, index) => {
                if (chart.innerHTML == value) {
                  setSelectIndex(index);
                }
              });
            },
          },
        },
      },
    }));
  };

  useEffect(() => {
    setSelectIndex(0);
    refresh();
  }, [summaryData]);

  useEffect(() => {
    refresh();
  }, [data, selectIndex]);

  return (
    <Styles.Container>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Styles.Container>
  );
}

export default BarChart;
