import Styles from "./styles";
import { date } from "utils";
import { layout } from "components";
import { IMAGEPATH } from "service/connector";
import { useBoard, useLanguage, useModals } from "hooks";

// img
import IconMore from "resources/image/icon/icon_more.svg";

function FeedDetailList({ data }) {
  const modalOption = useModals();
  const boardInfo = useBoard({ detail: data.id })
  const detail = boardInfo.getBoardDetail?.data?.data || []
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  const renderDetail = (type) => {
    // type: 1 - 눈바디 / 2 - 식사 / 3 - 운동 / 4 - 기타
    switch (type) {
      case 1:
        return (
          <>
            {data.weight && <p className="weight">{data.weight}kg</p>}
            {detail.state &&
              <div className="body">
                <div className="title">{languageQuarter ? "눈바디 사이즈 변화" : "Body size change"}</div>
                <div className="size">{detail.state}</div>
              </div>
            }
          </>
        );
      case 2:
        return (
          <>
            <p className="meals">{detail.title}</p>

            <ul className="mealInfoListWrap">
              <li className="mealInfoList">
                <div className="title">{languageQuarter ? "탄" : "C"}</div>
                {Math.round(detail.carbohydrate_per * (detail.eat_range / 100))}g
              </li>

              <li className="mealInfoList">
                <div className="title">{languageQuarter ? "단" : "P"}</div>
                {Math.round(detail.protein_per * (detail.eat_range / 100))}g
              </li>

              <li className="mealInfoList">
                <div className="title">{languageQuarter ? "지" : "F"}</div>
                {Math.round(detail.fat_per * (detail.eat_range / 100))}g
              </li>

              <li className="mealInfoList">
                <div className="title">{languageQuarter ? "식사량" : "Amount"}</div>
                {detail.eat_range}%
              </li>

              {detail["state" + languageInfo.suffix] &&
                <li className="mealInfoList">
                  <div className="title">{languageQuarter ? "포만감" : "Fullness"}</div>
                  {detail["state" + languageInfo.suffix]}
                </li>
              }
            </ul>
          </>
        );
      case 3:
        return (
          <>
            <p className="exercise">{detail?.work_list?.map(v => v.title).join(', ')}</p>

            <div className="exerciseInfo">
              <div className="title">{languageQuarter ? "운동시간" : "Total time"}</div>
              <div className="exerciseTime">{
                detail.work_list
                  ?.map(v => parseInt(v.work_min))
                  ?.reduce((a, b) => a + b, 0)
              }{languageQuarter ? "분" : "min"}</div>
            </div>
          </>
        );
      case 4:
        return (
          <>
            <p className="etcTitle">{detail.title}</p>

            <div className="emotionWrap">
              <div className="title">{languageQuarter ? "감정" : "Emotion"}</div>
              <div className="emotion">{detail.state}</div>
            </div>
          </>
        );
    }
  };

  return (
    <Styles.Container>
      <div className="timeContainer">
        <div className="bg">
          <img src={IMAGEPATH + data.image} alt="timeBg" />
        </div>

        <p className="date">{detail.created_at?.split(" ")[0]
          ? date.getDateType(detail.created_at?.split(" ")[0], "/")
          : "----/--/--"
        }</p>
        <p className="time">{detail.created_at?.split(" ")[1].slice(0, 5) || "--:--"}</p>

        <button
          type="button"
          className="moreButton"
          onClick={() => {
            modalOption.setMoreBottomSheetModal((e) => {
              e.show = true;
              e.data = data;

              return { ...e };
            });
          }}
        >
          <img src={IconMore} alt="IconMore" />
        </button>
      </div>

      <layout.FeedList data={data} big />

      <div className="detailContainer">
        <>
          {renderDetail(data.category_info_id)}

          {detail.memo && (
            <div className="noteWrap">
              <div className="noteTitle">note</div>
              <p className="note">{detail.memo}</p>
            </div>
          )}
        </>

        <button
          type="button"
          className="moreButton"
          onClick={() => {
            modalOption.setMoreBottomSheetModal((e) => {
              e.show = true;
              e.data = data;

              return { ...e };
            });
          }}
        >
          <img src={IconMore} alt="IconMore" />
        </button>
      </div>
    </Styles.Container>
  );
}

export default FeedDetailList;
