import Styles from "./styles";
import utils from "utils";
import { ui } from "components";
import { useRef } from "react";
import { useLanguage, useModals } from "hooks";

function SignupCompletePage() {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";
  const modalOption = useModals();

  const isLoad = useRef(false);

  return (
    <Styles.Container>
      <div className="inner">
        <p className="completeText">{languageQuarter ? "가입완료" : "Sign-Up Completed"}</p>
        {languageQuarter
          ? <p className="goalText">
            목표를 향해 <br />
            힘차게 달려요!
          </p>
          : <p className="goalText">
            Let's work hard <br />
            towards your goal!
          </p>
        }

        <div className="startButton">
          <ui.button.BasicButton
            buttonText={languageQuarter ? "시작하기" : "Get Started"}
            onClick={() => {
              if (isLoad.current == false)
                modalOption.setPushModal((e) => {
                  e.show = true;
                  return { ...e };
                });
              else {
                utils.etc.callNative("moveToMainToPage", "Stamp", "눈바디");
              }
              isLoad.current = true;
            }}
          />
        </div>
      </div>
    </Styles.Container>
  );
}

export default SignupCompletePage;
