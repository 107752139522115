import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: 100dvh;

  & header {
    position: relative;
    flex-shrink: 0;
    // height: calc(100vw * (119 / 390));
    // padding-top: calc(100vw * (65 / 390));

    height: calc(100vw * (140 / 390));
    padding-top: calc(100vw * (80 / 390));

    z-index: 8;
    overflow: hidden;

    & .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      filter: blur(calc(100vw * (40 / 390)));
      z-index: -1;
    }
  }

  & .tab {
    display: flex;
    justify-content: space-between;

    & .tabList {
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100vw * (34 / 390));
      padding: 0 calc(100vw * (14 / 390));
      font-weight: 300;
      color: #fff;
      border: calc(100vw * (1 / 390)) solid rgba(255, 255, 255, 0.5);
      border-radius: calc(100vw * (100 / 390));
      cursor: pointer;

      &.on {
        background-color: rgba(9, 9, 9, 0.5);
      }
    }
  }

  & main {
    flex-grow: 1;
    max-height: calc(100vh - calc(100vw * (119 / 390)));
    max-height: calc(100dvh - calc(100vw * (119 / 390)));
    overflow: auto;
  }
`;

export { Container };

export default { Container };
