// import useModals from "hooks/useModals";
import { api } from "service";
import { DOMAIN } from "service/connector";
// import { useAtom } from "jotai";
// import { authAtom } from "store/atoms";
// import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";

export default function useAuth() {
  const navigate = useNavigate();
  // const modalOption = useModals();
  // const [userData, setUserData] = useAtom(authAtom.userInfo);
  // const isLogin = userData.name == "" || userData.name == null ? false : true;

  // const userQuery = useQuery("userData", api.auth.getUser, {
  //   onSuccess: (e) => {
  //     if (e == null) {
  //     } else {
  //       setUserData(e);
  //     }
  //   },
  //   onError: (error) => {
  //     console.log(error);
  //   },
  //   refetchOnWindowFocus: false,
  // });

  const socialLogin = async (type) => {
    return await api.auth.socialLogin(type);
  };

  const socialSignUp = async (data) => {
    const result = await api.auth.socialSignUp(JSON.stringify(data));
    if (result.success) {
      document.cookie = `jwt=${result?.data};path=/;domain=${DOMAIN}`;
      // await userQuery.refetch();
      result.success && navigate("/signup/complete");
    }
  };

  const getUserInfo = useQuery(
    ["userInfo"],
    () => {
      return api.auth.getUserInfo();
    },
    {
      refetchOnWindowFocus: false,
      enabled: true,
    }
  );

  const updateUserInfo = async (data) => {
    const result = await api.auth.userUpdate(JSON.stringify(data));
    if (result.success) {
      getUserInfo.refetch();
    }
  };

  // const authInfo = useAuth({});
  // const queryClient = useQueryClient();

  // const toggleScrap = async (relateInfoId, index, type) => {
  //   if (authInfo.certification()) {
  //     doPush({
  //       relate_info_id: relateInfoId,
  //       index: index,
  //       type: type
  //     });
  //   }
  // }

  // const { mutate: doPush } = useMutation(
  //   (e) => { return api.auth.userUpdate(JSON.stringify(e)) },
  //   {
  //     async onMutate(variables) {
  //       queryClient.setQueryData(["userInfo"],
  //         e => {
  //           if (e.data) {
  //             if (e.data.length > 0) {
  //               const scrap = e.data.filter(v => { return v.type == variables.type })[0].list[variables.index]
  //               scrap.scrap_yn = scrap.scrap_yn === "Y" ? "N" : "Y"
  //             } else {
  //               e.data.scrap_yn = e.data.scrap_yn === "Y" ? "N" : "Y"
  //             }
  //           } else {
  //             e.list[variables.index].scrap_yn = e.list[variables.index].scrap_yn === "Y" ? "N" : "Y"
  //           }
  //           return { ...e };
  //         }
  //       )
  //     },
  //     onError(error, variables, context) {
  //       context && context();
  //     },
  //   }
  // );

  const logout = () => {
    // setUserData(authAtom.userInfo);
    document.cookie = `jwt=;path=/;domain=${DOMAIN};max-age:-1`;
    document.cookie = `jwt=;path=/;domain=.${DOMAIN};max-age:-1`;

    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: "LOGOUT" }));
    }
  };

  // const logoutModal = async () => {
  //   modalOption.setDefaultModal((e) => {
  //     e.show = true;
  //     e.title = "로그아웃 하시겠습니까?";
  //     e.text = (
  //       <>
  //         로그인 상태를 유지하는 편이 편하게 <br />
  //         댓글이나 스크랩을 이용하실 수 있습니다.
  //       </>
  //     );
  //     e.confirmButtonText = "로그아웃";
  //     e.confirmButtonClick = () => {
  //       logout();
  //       modalOption.setDefaultModal((e) => {
  //         e.show = true;
  //         e.title = "로그아웃 되었습니다!";
  //         e.text = false;
  //         e.confirmButtonText = "메인으로";
  //         e.confirmButtonClick = () => {
  //           window.location.replace("/");
  //         };
  //         e.cancelButtonText = false;
  //         return { ...e };
  //       });
  //     };
  //     e.cancelButtonText = "취소";
  //     e.cancelButtonClick = false;
  //     return { ...e };
  //   });
  // };

  const resign = async () => {
    const result = await api.auth.resign(
      JSON.stringify({
        reason: "",
      })
    );
  };

  return {
    // userData,
    // setUserData,
    // isLogin,
    // getValidateToken,

    // isLoad: userQuery.isLoading,

    // findNickName,

    // certification,

    socialLogin,
    socialSignUp,
    getUserInfo,
    updateUserInfo,
    // logoutModal,
    resign,
    logout,
  };
}
