import Styles from "./styles";
import { ui } from "components";
import { DOMAIN } from "service/connector";
import { useLanguage } from "hooks";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

function SignupPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [serchParams] = useSearchParams();
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  const [inputs, setInputs] = useState({
    name: "",
    date: "",
    stature: "",
    nowWeight: "",
    goalWeight: "",
  });
  const [gender, setGender] = useState("");
  const [privacy, setPrivacy] = useState(true);
  const [use, setUse] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);

  const data = [
    {
      id: "name",
      labelText: languageQuarter ? "이름" : "Name",
      name: "name",
      value: inputs.name,
      placeholder: languageQuarter ? "본명" : "real name",
    },
    {
      id: "date",
      labelText: languageQuarter ? "생년월일" : "Birth Date",
      name: "date",
      value: inputs.date,
      placeholder: "YYYYMMDD",
    },
    {
      id: "stature",
      labelText: languageQuarter ? "키" : "Height",
      name: "stature",
      value: inputs.stature,
      placeholder: "cm",
    },
    {
      id: "nowWeight",
      labelText: languageQuarter ? "현재 체중" : "Now Weight",
      name: "nowWeight",
      value: inputs.nowWeight,
      placeholder: "kg",
    },
    {
      id: "goalWeight",
      labelText: languageQuarter ? "목표 체중" : "Goal",
      name: "goalWeight",
      value: inputs.goalWeight,
      placeholder: "kg",
    },
  ];
  const genderList = [
    {
      id: "F",
      labelText: languageQuarter ? "여성" : "F",
    },
    {
      id: "M",
      labelText: languageQuarter ? "남성" : "M",
    },
  ];

  useEffect(() => {
    if (serchParams.get("token")) {
      document.cookie = `jwt=${serchParams.get(
        "token"
      )};path=/;domain=${DOMAIN}`;
    }
  }, []);

  useEffect(() => {
    if (validate()) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [inputs, gender, privacy, use]);

  useEffect(() => {
    if (location.state) {
      setInputs({
        name: location.state.name,
        date: location.state.birthday,
        stature: location.state.height && location.state.height + "cm",
        nowWeight: location.state.weight && location.state.weight + "kg",
        goalWeight: location.state.target_weight && location.state.target_weight + "kg",
      })
      setGender(location.state.gender);
      setPrivacy(location.state.privacy);
      setUse(location.state.use);
    }
  }, [location]);

  const validate = () => {
    if (!inputs.name?.toString()?.trim().length) return false;
    if (inputs.date?.toString()?.length !== 8) return false;
    if (gender === "") return false;
    if (`${inputs.stature}`.replace(/[^0-9]/g, "")?.length !== 3) return false;
    if (
      `${inputs.nowWeight}`?.replace(/[^0-9]/g, "")?.length < 2 ||
      `${inputs.nowWeight}`?.replace(/[^0-9]/g, "")?.length > 3
    ) return false;
    if (
      `${inputs.goalWeight}`?.replace(/[^0-9]/g, "")?.length < 2 ||
      `${inputs.goalWeight}`?.replace(/[^0-9]/g, "")?.length > 3
    ) return false;
    if (!(privacy && use)) return false;
    return true;
  };

  const onChange = (e, id) => {
    setInputs((v) => ({ ...v, [id]: parseInt(e.target.value?.replace(/[^0-9]/g, "")) || "" }));
  };

  const onNext = () => {
    navigate("/find-out", {
      state: {
        name: inputs.name,
        birthday: inputs.date,
        gender: gender,
        height: inputs.stature?.replace(/[^0-9]/g, ""),
        weight: inputs.nowWeight.replace(/[^0-9]/g, ""),
        target_weight: inputs.goalWeight.replace(/[^0-9]/g, ""),
        mail_yn: "Y",
        marketing_yn: "Y",
        message_yn: "Y",
        push_yn: "Y",
        policy_yn: "Y",
        personal_policy_yn: "Y",
      },
    });
  };

  return (
    <Styles.Container>
      <div className="inner">
        <h1>{languageQuarter ? "정보를 입력해주세요." : "Please enter information."}</h1>

        <ul className="inputListWrap">
          <li className="inputList">
            <ui.input.BasicInput data={data[0]} setInputs={setInputs} />
          </li>

          <li className="inputList">
            <ui.input.BasicInput
              data={data[1]}
              setInputs={setInputs}
              onChange={(e) => onChange(e, data[1].id)}
            />
          </li>

          <li className="inputList">
            <label className="label">{languageQuarter ? "성별" : "Gender"}</label>
            <ul className="radioListWrap">
              {genderList?.map((item, index) => {
                return (
                  <li className="radioList" key={"radioList" + index}>
                    <ui.input.BasicRadio
                      data={item}
                      name={"gender"}
                      check={gender}
                      setCheck={setGender}
                    />
                  </li>
                );
              })}
            </ul>
          </li>

          <li className="inputList">
            <ui.input.BasicInput
              data={data[2]}
              setInputs={setInputs}
              onChange={(e) => onChange(e, data[2].id)}
              onFocusEvent={() => {
                setInputs((e) => ({
                  ...e,
                  [data[2].id]: data[2].value?.replace(/[^0-9]/g, ""),
                }));
              }}
              onBlurEvent={() => {
                setInputs((e) => ({
                  ...e,
                  [data[2].id]: data[2].value ? data[2].value + "cm" : "",
                }));
              }}
            />
          </li>

          <li className="inputList">
            <ui.input.BasicInput
              data={data[3]}
              setInputs={setInputs}
              onChange={(e) => onChange(e, data[3].id)}
              onFocusEvent={() => {
                setInputs((e) => ({
                  ...e,
                  [data[3].id]: data[3].value?.replace(/[^0-9]/g, ""),
                }));
              }}
              onBlurEvent={() => {
                setInputs((e) => ({
                  ...e,
                  [data[3].id]: data[3].value ? data[3].value + "kg" : "",
                }));
              }}
            />
          </li>

          <li className="inputList">
            <ui.input.BasicInput
              data={data[4]}
              setInputs={setInputs}
              onChange={(e) => onChange(e, data[4].id)}
              onFocusEvent={() => {
                setInputs((e) => ({
                  ...e,
                  [data[4].id]: data[4].value?.replace(/[^0-9]/g, ""),
                }));
              }}
              onBlurEvent={() => {
                setInputs((e) => ({
                  ...e,
                  [data[4].id]: data[4].value ? data[4].value + "kg" : "",
                }));
              }}
            />
          </li>
        </ul>

        <ul className="termsListWrap">
          <li className="termsList">
            <ui.input.BasicCheckbox
              id={"privacy"}
              checkText={languageQuarter ? "[필수] 개인정보 처리 동의" : "[Required] Privacy Policy"}
              checked={privacy}
              onChange={(e) => {
                setPrivacy(e.target.checked);
              }}
            />
            <button
              type="button"
              className="detailTermsButton"
              onClick={() => {
                navigate("/terms/privacy", { state: {
                  name: inputs.name,
                  birthday: inputs.date,
                  gender: gender,
                  height: inputs.stature?.replace(/[^0-9]/g, ""),
                  weight: inputs.nowWeight?.replace(/[^0-9]/g, ""),
                  target_weight: inputs.goalWeight?.replace(/[^0-9]/g, ""),
                  privacy: privacy,
                  use: use,
                }});
              }}
            >
              {languageQuarter ? "자세히보기" : "more details"}
            </button>
          </li>
          <li className="termsList">
            <ui.input.BasicCheckbox
              id={"use"}
              checkText={languageQuarter ? "[필수] 이용약관 동의" : "[Required] Terms & Conditions"}
              checked={use}
              onChange={(e) => {
                setUse(e.target.checked);
              }}
            />
            <button
              type="button"
              className="detailTermsButton"
              onClick={() => {
                navigate("/terms/use", { state: {
                  name: inputs.name,
                  birthday: inputs.date,
                  gender: gender,
                  height: inputs.stature?.replace(/[^0-9]/g, ""),
                  weight: inputs.nowWeight?.replace(/[^0-9]/g, ""),
                  target_weight: inputs.goalWeight?.replace(/[^0-9]/g, ""),
                  privacy: privacy,
                  use: use,
                }});
              }}
            >
              {languageQuarter ? "자세히보기" : "more details"}
            </button>
          </li>
        </ul>

        <div className="nextButton">
          <ui.button.BasicButton
            buttonText={languageQuarter ? "다음" : "Done"}
            disabled={isDisabled}
            onClick={onNext}
          />
        </div>
      </div>
    </Styles.Container>
  );
}

export default SignupPage;
