import { Route, Routes, BrowserRouter } from "react-router-dom";
import { ModalPage } from "pages";
import { CustomRoute } from "routes";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<CustomRoute></CustomRoute>} />
      </Routes>
      <ModalPage />
    </BrowserRouter>
  );
}
