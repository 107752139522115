import Styles from "./styles";
import usePolicy from "hooks/usePolicy";
import { ui } from "components";
import { useLanguage } from "hooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";

function TermsPage() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const policyInfo = usePolicy({ type: id === "privacy" ? 2 : 0 });
  const description = policyInfo.getPolicy?.data?.data || "";
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  return (
    <Styles.Container>
      <div className="inner">
        <ui.button.PrevButton
          prevEvent={() => {
            location.state
              ? navigate("/signup/stepA", { state: location.state })
              : navigate(-1)
          }}
          text={id === "privacy"
            ? languageQuarter ? "개인정보처리방침" : "Privacy Policy"
            : languageQuarter ? "이용약관" : "Terms and Conditions"
          }
        />

        <div dangerouslySetInnerHTML={{ __html: description["description" + languageInfo.suffix] }}></div>
      </div>
    </Styles.Container>
  );
}

export default TermsPage;
