import Styles from "./styles";
import ui from "components/ui";
import { useAuth, useLanguage } from "hooks";

// img
import IconDelete from "resources/image/icon/icon_delete_white.svg";
import { ip } from "service/connector";

function ShareModal({ modalClose }) {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";
  const authInfo = useAuth();
  const benefitList = languageQuarter
    ? ["광고제거", "사진 로고 \n삭제 가능", "통계 기능 \n열람"]
    : ["Remove \nAds", "Remove \nLogo", "View \nStatisics \nFeature"]

  const doShare = () => {
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: "SHAREURL", url: ip + "share" })
      );
      return false;
    }

    authInfo.updateUserInfo({
      share_yn: "Y",
    });
    sessionStorage.setItem("popup_yn", "N");
    modalClose();
  };

  const close = () => {
    authInfo.updateUserInfo({
      share_yn: "P",
    });
    sessionStorage.setItem("popup_yn", "N");
    modalClose();
  };

  return (
    <Styles.Container>
      <div className="modalInner">
        <button type="button" className="closeButton" onClick={close}>
          <img src={IconDelete} alt="IconDelete" />
        </button>

        <div className="top">
          <p className="title">
            {languageQuarter ? "친구한테 공유하고" : "By sharing with friends"}</p>
          
          {languageQuarter
            ? <p className="text">
              프리미엄 1개월권을 <br />
              무료로 이용해보세요
            </p>
            : <p className="text">
              Enjoy a free trial of the <br />
              premium 1-month plan!
            </p>
          }

          <div className="tag">
            {languageQuarter ? "프리미엄 혜택" : "Premium benefits"}</div>
        </div>

        <div className="bottom">
          <ul className="benefitListWrap">
            {benefitList.map((item, index) => {
              return (
                <li className="benefitList" key={"benefitList" + index}>
                  {item}
                </li>
              );
            })}
          </ul>

          {languageQuarter
            ? <p className="guideText">
              *프리미엄 서비스 무료 체험은 <br />
              친구가 앱에 들어올 때 시작됩니다.</p>
            : <p className="guideText">
              *The free trial of the premium service <br />
              begins when a friend joins the app.</p>
          }

          <div className="shareButton">
            <ui.button.BasicButton
              buttonText={languageQuarter
                ? "친구에게 공유하기"
                : "Share with Friends"
              }
              sizeType={"height52"}
              onClick={doShare}
            />
          </div>
        </div>
      </div>

      <div className="overlay"></div>
    </Styles.Container>
  );
}

export { ShareModal };

export default ShareModal;
