import Styles from "./styles";
import { callNative } from "utils/etc";
import { useLanguage, useModals } from "hooks";

// img
import IconShare from "resources/image/icon/icon_share.svg";
import IconDownload from "resources/image/icon/icon_download.svg";
import IconEdit from "resources/image/icon/icon_edit.svg";
import IconTrash from "resources/image/icon/icon_trash.svg";

function MoreBottomSheetModal({ modalClose, modalOption }) {
  const modal = useModals();
  const editData = modalOption.data || {};
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  const onEdit = () => {
    switch (editData.category_info_id) {
      case 1:
        return callNative("moveToPage", "EyeBody", {
          id: editData.id,
          weight: modalOption.data.weight,
          path: editData.image,
        });
      case 2:
        return callNative("moveToPage", "Meal", {
          id: editData.id,
          path: editData.image,
        });
      case 3:
        return callNative("moveToPage", "Exercise", {
          id: editData.id,
          path: editData.image,
        });
      case 4:
        return callNative("moveToPage", "Etc", {
          id: editData.id,
          path: editData.image,
        });
    }
  };

  const list = [
    {
      icon: IconShare,
      text: languageQuarter ? "공유하기" : "Share",
      onclick: () => {
        modal.setToastModal((e) => {
          e.show = true;
          e.text = languageQuarter
            ? "공유가 완료되었습니다."
            : "The sharing has been completed."

          return { ...e };
        });
      },
    },
    {
      icon: IconDownload,
      text: languageQuarter ? "저장하기" : "Save",
      onclick: () => {
        modal.setToastModal((e) => {
          e.show = true;
          e.text = languageQuarter
            ? "저장이 완료되었습니다."
            : "The save has been completed."

          return { ...e };
        });
      },
    },
    {
      icon: IconEdit,
      text: languageQuarter ? "수정하기" : "Edit",
      onclick: () => {
        onEdit();
        // modal.setToastModal((e) => {
        //   e.show = true;
        //   e.text = languageQuarter
        //     ? "수정이 완료되었습니다."
        //     : "Editing has been completed."

        //   return { ...e };
        // });
      },
    },
    {
      icon: IconTrash,
      text: languageQuarter ? "삭제하기" : "Delete",
      onclick: () => {
        modal.setFeedDeleteModal((e) => {
          e.show = true;
          e.id = modalOption.data.id;

          return { ...e };
        });
      },
    },
  ];

  return (
    <Styles.Container>
      <div className="modalInner">
        <button
          type="button"
          className="closeButton"
          onClick={modalClose}
        ></button>

        <ul className="optionListWrap inner">
          {list?.map((item, index) => {
            return (
              <li
                className="optionList"
                key={"optionList" + index}
                onClick={() => {
                  item.onclick();
                  modalClose();
                }}
              >
                <div className="icon">
                  <img src={item.icon} alt="optionIcon" />
                </div>

                <p className="text">{item.text}</p>
              </li>
            );
          })}
        </ul>
      </div>

      <div className="overlay"></div>
    </Styles.Container>
  );
}

export { MoreBottomSheetModal };

export default MoreBottomSheetModal;
