import Styles from "./styles";
import { callNative } from "utils/etc";
import { useLanguage } from "hooks";
import { useNavigate, useLocation } from "react-router-dom";
import { useRef, useEffect, useState } from "react";

// img
import IconHome from "resources/image/icon/icon_home.svg";
import IconStamp from "resources/image/icon/icon_stamp.svg";
import IconUser from "resources/image/icon/icon_user.svg";
import IconBody from "resources/image/icon/icon_body.svg";
import IconMeal from "resources/image/icon/icon_meal.svg";
import IconExercise from "resources/image/icon/icon_exercise.svg";
import IconOthers from "resources/image/icon/icon_others.svg";

function NavigationBar({}) {
  const navigate = useNavigate();
  const ref = useRef(null);
  const location = useLocation();
  const pathName = location.pathname.split("/")[1];

  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  const [isOpen, setIsOpen] = useState();

  useEffect(() => {
    // 특정 영역 외 클릭 시 발생하는 이벤트
    function handleFocus(e) {
      if (ref.current && !ref.current.contains(e.target)) {
        setIsOpen(false);
      }
    }

    // 이벤트 리스너에 handleFocus 함수 등록
    document.addEventListener("mouseup", handleFocus);
    return () => {
      document.removeEventListener("mouseup", handleFocus);
    };
  }, [ref]);

  const buttonList = [
    {
      class: "home",
      icon: IconHome,
      onclick: () => {
        navigate("/");
      },
      active: (pathName === "" || pathName === "feed") && " on",
    },
    {
      class: "stamp",
      icon: IconStamp,
      onclick: () => {
        setIsOpen(!isOpen);
      },
    },
    {
      class: "mypage",
      icon: IconUser,
      onclick: () => {
        navigate("/mypage");
      },
      active: pathName === "mypage" && " on",
    },
  ];

  const stampList = [
    {
      icon: IconBody,
      text: languageQuarter ? "눈바디" : "Body",
      link: () => {
        callNative("moveToPage", "Stamp", "눈바디");
        setIsOpen(false);
      },
    },
    {
      icon: IconMeal,
      text: languageQuarter ? "식사" : "Meal",
      link: () => {
        callNative("moveToPage", "Stamp", "식사");
        setIsOpen(false);
      },
    },
    {
      icon: IconExercise,
      text: languageQuarter ? "운동" : "Workout",
      link: () => {
        callNative("moveToPage", "Stamp", "운동");
        setIsOpen(false);
      },
    },
    {
      icon: IconOthers,
      text: languageQuarter ? "기타" : "Etc",
      link: () => {
        callNative("moveToPage", "Stamp", "기타");
        setIsOpen(false);
      },
    },
  ];

  return (
    <Styles.Container>
      {buttonList.map((item, index) => {
        return (
          <button
            type="button"
            key={"buttonList" + index}
            onClick={item.onclick}
            className={item.class + (item.active ? item.active : "")}
          >
            <span className="icon">
              <img src={item.icon} alt="buttonIcon" />
            </span>
          </button>
        );
      })}

      {isOpen && (
        <ul className="stampListWrap" ref={ref}>
          {stampList.map((item, index) => {
            return (
              <li
                className="stampList"
                key={"stampList" + index}
                onClick={item.link}
              >
                <div className="icon">
                  <img src={item.icon} alt="" />
                </div>

                <p className="stampText">{item.text}</p>
              </li>
            );
          })}
        </ul>
      )}
    </Styles.Container>
  );
}

export default NavigationBar;
