export default function useLanguage() {
  const setLanguage = (item) => {
    return sessionStorage.setItem("language", item);
  };

  const suffix = sessionStorage.getItem("language") || "";

  return {
    setLanguage,
    suffix,
  };
}
