import styled from "styled-components";

const Container = styled.main`
  & .inner {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-height: 100dvh;
    padding: calc(100vw * (50 / 390)) 0;
  }

  & h1 {
    margin-bottom: calc(100vw * (50 / 390));
    font-size: calc(100vw * (25 / 390));
    font-weight: 600;
  }

  & .inputListWrap {
    display: flex;
    flex-wrap: wrap;
    gap: calc(100vw * (30 / 390)) calc(100vw * (20 / 390));
    justify-content: space-between;
    margin-bottom: calc(100vw * (78 / 390));

    & .inputList:where(:nth-child(1), :nth-child(4)) {
      width: 100%;
    }

    & .inputList:nth-child(3) {
      width: calc(100vw * (143 / 390));
    }

    & .inputList:where(:nth-child(2), :nth-child(5), :nth-child(6)) {
      width: calc(100vw * (161 / 390));
    }

    & .label {
      display: block;
      margin-bottom: calc(100vw * (10 / 390));
      font-size: calc(100vw * (20 / 390));
      font-weight: 600;
    }

    & .radioListWrap {
      display: flex;
      gap: calc(100vw * (3 / 390));

      & .radioList {
        flex: 1;
      }
    }
  }

  & .termsListWrap {
    margin-bottom: calc(100vw * (27 / 390));

    & .termsList {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: calc(100vw * (10 / 390));
      }

      & .detailTermsButton {
        font-size: calc(100vw * (12 / 390));
        font-weight: 300;
        color: var(--black80);
        text-decoration: underline;
        text-underline-offset: calc(100vw * (2 / 390));
      }
    }
  }

  & .nextButton {
    margin-top: auto;
  }
`;

export { Container };

export default { Container };
