import Styles from "./styles";
import ui from "components/ui";
import { useLanguage } from "hooks";
import { useNavigate } from "react-router-dom";

// img
import IconDelete from "resources/image/icon/icon_delete_white.svg";

function SubscriptionCompleteModal({ modalClose }) {
  const navigate = useNavigate();
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  const close = () => {
    navigate("/", { replace: true });
    modalClose();
  };

  return (
    <Styles.Container>
      <button type="button" className="closeButton" onClick={close}>
        <img src={IconDelete} alt="IconDelete" />
      </button>

      <div className="inner">
        <div className="tag">{languageQuarter ? "결제 완료!" : "Payment completed!"}</div>

        <p className="title satoshi">Good!</p>

        <p className="text">{languageQuarter ? "탁월한 선택이에요." : "It's an excellent choice."}</p>

        <div className="nextButton">
          <ui.button.BasicButton
            buttonText={languageQuarter ? "바로 시작하기" : "Get Started"}
            colorType={"white"}
            onClick={close}
          />
        </div>
      </div>
    </Styles.Container>
  );
}

export { SubscriptionCompleteModal };

export default SubscriptionCompleteModal;
